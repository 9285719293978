// 直播
import request from '../utils/request';

// 获取直播面经题目列表
export async function getLiveContentList(params) {
    try {
        return await request({
            url: `/posts/interview`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}

// 获取直播详情
export async function getLiveDetail() {
    try {
        return await request({
            url: `/liveStreaming`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

// 获取直播详情
export async function getLiveLink(polyvId) {
    try {
        return await request({
            url: `/liveStreaming/getLink/`+polyvId,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

// 订阅直播
export async function subscribeLive(data) {
    try {
        return await request({
            url: `/liveStreaming/subscription`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}
// 获取订阅直播详情
export async function getLiveSubscription() {
    try {
        return await request({
            url: `/liveStreaming/subscription`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}