<template>
  <!-- 邮件订阅提醒 -->
  <el-dialog :visible.sync="dialogVisible" width="500px">
    <div style="text-align: center; width: 300px; margin: 0 auto">
      <div class="title">邮箱订阅开播提醒</div>
      <div class="subtitle">直播3天前收到最新邮件消息</div>
      <el-form ref="form" :model="form">
        <el-form-item name="interviewQuestions">
          <el-input
            placeholder="请输入你的邮箱"
            v-model="form.email"
          ></el-input>
        </el-form-item>
        <el-form-item name="companyId" style="margin-bottom: 12px">
          <el-autocomplete
            v-model="searchCompanyKey"
            :fetch-suggestions="querySearch"
            :trigger-on-focus="false"
            placeholder="请输入你想关注的公司（可多选）"
            :popper-append-to-body="false"
            @input="searchLodash"
            @select="selectCompany"
          >
            <template slot-scope="{ item }" v-if="companys.length > 0">
              {{ item.name }}
            </template>
          </el-autocomplete>
          <div style="text-align: left">
            <el-tag
              :key="tag.id"
              v-for="tag in focusCompany"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
              style="margin-right: 12px"
            >
              {{ tag.name }}
            </el-tag>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" style="text-align: center">
      <el-button
        v-if="!hasSubscribed"
        type="success"
        style="width: 300px"
        @click="subscribeInterview"
        >订阅直播面经</el-button
      >
      <el-button
        v-else
        type="info"
        style="width: 300px"
        @click="subscribeInterview"
        >更新订阅</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { searchCompanys } from "@/service/company";
import {
  getLiveDetail,
  subscribeLive,
  getLiveSubscription,
} from "@/service/live";
import _ from "lodash";
export default {
  name: "emailSubscribe",
  props: {
    type: {
      type: String,
      default: "all",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        email: "",
        companyId: [],
      },
      companys: [],
      searchCompanyKey: "",
      timeout: null,
      focusCompany: [],
      hasSubscribed: false,
    };
  },
  methods: {
    isEmail(str) {
      var reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      return reg.test(str);
    },
    querySearch(queryString, cb, type) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(queryString == "" ? [] : this.companys);
      }, 1000);
    },
    searchLodash: _.debounce(function () {
      this.searchCompanys();
    }, 1000),
    searchCompanys() {
      searchCompanys(this.searchCompanyKey).then((res) => {
        if (res.success) {
          this.companys = res.result;
        }
      });
    },
    selectCompany(option) {
      if (this.focusCompany.length >= 10) {
        return this.$message.error("最多关注10个公司！");
      }
      this.focusCompany.push(option);
    },
    handleClose(tag) {
      this.focusCompany.splice(this.focusCompany.indexOf(tag), 1);
    },
    subscribeInterview() {
      if (this.form.email == "") {
        return this.$message.error("请输入邮箱！");
      }
      if (!this.isEmail(this.form.email)) {
        return this.$message.error("邮箱格式错误！");
      }
      if (this.focusCompany.length == 0) {
        return this.$message.error("请选择关注的公司！");
      }
      this.form.companyId = this.focusCompany.map((item) => {
        return item.id;
      });
      subscribeLive(this.form).then((res) => {
        if (res.success) {
          this.hasSubscribed = true;
          this.$emit("subscribeEvent");
          this.$message.closeAll();
          this.$message.success("订阅成功！");
        }
      });
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("changeVisible", val);
      },
    },
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        getLiveDetail().then((res) => {
          if (res.success) {
            this.hasSubscribed = res.result.subscription;
          }
        });
        getLiveSubscription().then((res) => {
          if (res.success) {
            this.form.email = res.result.email ? res.result.email : "";
            this.focusCompany = res.result.company ? res.result.company : [];
          }
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.el-autocomplete {
  width: 100%;
}
.title {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #34495e;
  line-height: 21px;
  margin-bottom: 10px;
}
.subtitle {
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: rgba(52, 73, 94, 0.8);
  line-height: 18px;
  margin-bottom: 24px;
}
::v-deep .el-dialog__body {
  padding: 0 20px;
}
::v-deep .el-dialog__footer {
  padding-bottom: 48px;
}
</style>