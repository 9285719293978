<template>
  <!-- 问面经题目 -->
  <el-dialog
    title="问面经题目"
    :visible.sync="questionVisible"
    @close="closeDialog"
    width="800px"
    append-to-body
  >
    <div>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item
          label="面试公司"
          required
          v-if="type == 'all'"
          name="companyId"
        >
          <el-autocomplete
            v-model="searchCompanyKey"
            :fetch-suggestions="querySearch"
            :trigger-on-focus="false"
            placeholder="输入公司名"
            :popper-append-to-body="false"
            @input="searchLodash"
            @select="selectCompany"
            @keydown="form.companyId = ''"
            @keydown.native.enter="selectDefaultCompany"
            @blur="blurEvents"
          >
            <template slot-scope="{ item }" v-if="companys.length > 0">
              {{ item.name }}
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="面经内容" name="interviewQuestions" required>
          <el-input
            v-model="form.interviewQuestions"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 10 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片描述" name="pictures">
          <el-upload
            ref="upload"
            :before-upload="beforeFileUpload"
            :on-success="handleFileSuccess"
            :limit="9"
            :on-remove="removeFile"
            :action="uploadUrl"
            :class="['upload-demo', showUpload === false ? 'hide' : '']"
            multiple
            accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
            style="display: inline-block"
            :data="{ isPublic: true }"
            :headers="{ 'X-Access-Token': token }"
            list-type="picture-card"
            :on-exceed="onExceed"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div style="text-align: center">
        <el-checkbox v-model="form.anonymity">匿名发布</el-checkbox>
      </div>
    </div>
    <div slot="footer" style="text-align: center">
      <el-button
        type="primary"
        class="saveBtn"
        size="mini"
        @click="publishQuestion"
        >保存发表</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { publishQuestion, searchCompanys } from "@/service/company";
import _ from "lodash";
export default {
  name: "createQuestion",
  props: {
    type: {
      type: String,
      default: "all",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        interviewQuestions: "",
        anonymity: false,
        companyId: "",
        companyName: "",
        pictures: [],
      },
      companys: [],
      searchCompanyKey: "",
      timeout: null,
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload?isPublic=true",
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      showUpload: true,
    };
  },
  methods: {
    publishQuestion() {
      if (this.type == "all") {
        if (this.form.companyId == "") {
          this.form.companyName = this.searchCompanyKey;
        }
        if (this.form.companyId == "" && this.form.companyName == "") {
          return this.$message.error("请选择公司！");
        }
      } else if (this.type == "company") {
        this.form.companyId = this.$route.query.id;
      }

      console.log(this.companyId, this.form);
      if (this.form.interviewQuestions == "") {
        return this.$message.error("请填写内容！");
      }

      publishQuestion({ ...this.form }).then((res) => {
        console.log(res);
        if (res.success) {
          this.$message.success("发布问题成功！");
          this.questionVisible = false;
          if (this.type == "all") {
            this.form.companyId = "";
          }
          this.form.interviewQuestions = "";
          this.form.anonymity = false;
          this.$emit("updateQuestionList");
        }
      });
    },
    querySearch(queryString, cb, type) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(queryString == "" ? [] : this.companys);
      }, 1000);
    },
    searchLodash: _.debounce(function () {
      this.searchCompanys();
    }, 1000),
    searchCompanys() {
      searchCompanys(this.searchCompanyKey).then((res) => {
        if (res.success) {
          this.companys = res.result;
        }
      });
    },
    selectCompany(option) {
      this.searchCompanyKey = option.name;
      this.form.companyId = option.id;
      console.log(this.form, option);
    },
    selectDefaultCompany() {
      if (this.companys.length > 0) {
        this.searchCompanyKey = this.companys[0].name;
        this.form.companyId = this.companys[0].id;
      } else {
        this.form.companyId = "";
      }
    },
    blurEvents() {
      if (this.companys.length > 0) {
        this.searchCompanyKey = this.companys[0].name;
        this.form.companyId = this.companys[0].id;
      } else {
        this.form.companyName = this.searchCompanyKey;
      }
    },
    closeDialog() {
      this.$emit("changeCreateQuestionVisible", false);
    },

    beforeFileUpload(file) {
      if (this.form.pictures.length >= 9) {
        return this.$message.error("最多只能上传9个文件!");
      }
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过10MB!");
      }
      return isLt10M;
    },
    handleFileSuccess(res) {
      this.form.pictures.push(res.result.objectKey);
      if (this.form.pictures.length >= 9) {
        this.showUpload = false;
      }
    },
    removeFile(file, fileList) {
      let removeIndex = this.form.pictures.indexOf(
        file.response.result.objectKey
      );
      this.form.pictures.splice(removeIndex, 1);
      this.showUpload = true;
    },
    onExceed() {
      this.$message.error("最多上传9个文件！");
    },
  },
  mounted() {},
  computed: {
    questionVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("changeCreateQuestionVisible", val);
      },
    },
  },
  watch: {
    questionVisible(val) {
      if (this.type == "all") {
        this.form.companyId = "";
      }
      this.form.interviewQuestions = "";
      this.searchCompanyKey = "";
      this.form.anonymity = false;
      this.form.pictures = [];
      this.$nextTick(() => {
        this.$refs.form.resetFields();
        this.$refs.upload.clearFiles();
      });
    },
  },
};
</script>
<style scoped lang="scss">
.el-autocomplete {
  width: 100%;
}
::v-deep .el-dialog__body {
  padding-bottom: 0;
}
::v-deep .el-dialog {
  padding: 12px 60px;
  border-radius: 8px;
}
::v-deep .saveBtn {
  width: 130px;
  background-color: #0075f6;
}
::v-deep .el-dialog__header {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    width: 3px;
    height: 20px;

    background: #0084ff;
    border-radius: 2px;
    left: 10px;
    top: 22px;
  }
}
::v-deep .el-dialog__headerbtn {
  font-size: 18px;
  right: -20px;
  top: 10px;
}
::v-deep .el-autocomplete-suggestion {
  z-index: 99999 !important;
}

::v-deep .hide .el-upload--picture-card {
  display: none !important;
}
::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
  .el-icon-plus {
    vertical-align: top;
    line-height: 100px;
  }
}
</style>