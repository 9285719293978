<template>
  <!-- 直播弹窗 -->
  <div class="submit-con">
    <div class="pv-box">
      <div class="pv-box-header">
        <div class="pv-box-header-left">直播简介</div>
        <div class="pv-box-header-right">
          <i
            class="el-icon-close delete"
            @click="hideVideo"
            :style="{ cursor: 'pointer' }"
          ></i>
        </div>
      </div>

      <div class="video-box">
        <div class="video-body" id="polyvideoLive"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "polyVideo",
  props: {
    title: {
      type: String,
      default: "课程简介 & 免费试听",
    },
  },
  data() {
    return {
      vodPlayerJs: "https://player.polyv.net/resp/vod-player-drm/canary/player.js",
      vId: "5c1d111f63618bac2bf44e31bf1faa8a_5",
      player: null,
      activeName: "1",
      showVideo: false,
    };
  },
  methods: {
    initVideo() {
      this.$nextTick(() => {
        this.loadPlayerScript(this.loadPlayer);
      });
    },
    hideVideo() {
      if (this.player) {
        this.player.destroy();
      }

      this.$emit("closeVideo");
    },
    closeVideo() {
      if (this.player) {
        this.player.destroy();
      }
    },
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },
    loadPlayer() {
      if (this.player) {
        this.player.destroy();
      }
      const polyvPlayer = window.polyvPlayer;
      this.player = polyvPlayer({
        wrap: "#polyvideoLive",
        width: 800,
        height: 400,
        vid: this.vId,
        df: 3,
      });
    },
  },
  mounted() {
    this.initVideo();
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
    }
  },
};
</script>

<style scoped>
.submit-con .el-collapse {
  width: 800px;
  background-color: #2b3039 !important;
}

.submit-con .el-collapse-item__wrap {
  background-color: black !important;
}

.submit-con .el-collapse {
  border-top: 0 solid #ebeef5 !important;
  border-bottom: 0 solid #ebeef5 !important;
}

.submit-con .el-collapse-item__header {
  height: 48px !important;
  line-height: 48px !important;
  background-color: #2b3039 !important;
  color: #fff !important;
  border-bottom: 0 solid #666 !important;
  font-size: 14px !important;
  padding-left: 20px !important;
}

.submit-con .el-collapse-item__content {
  padding-bottom: 0 !important;
}

.submit-con .el-collapse-item__wrap {
  border-bottom: 0 solid #ebeef5 !important;
}

.el-collapse-item.is-disabled .el-collapse-item__header {
  cursor: pointer !important;
}

.pv-box {
  background: #2b3039 !important;
  padding: 10px;
  border-radius: 8px;
}

.pv-box-header {
  color: #fff;
  display: flex;
  justify-content: space-between;
  width: 800px;
}

.pv-box-header-left {
  padding-left: 5px;
  font-size: 16px;
}

.pv-box-header-right {
  padding-right: 5px;
  font-size: 20px;
}

.pv-title-img {
  height: 14px;
  width: 14px;
  margin-right: 10px;
}
</style>
