// 公司
import request from '../utils/request';
// 获取公司列表
export async function getCompany(isDomestic, keyWord, current) {
    try {
        return await request({
            url: `/company?current=${current ?current:1}&isDomestic=${isDomestic}&keyWord=${keyWord?keyWord:''}&size=12`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}
// 获取公司详情
export async function getCompanyDetail(id) {
    try {
        return await request({
            url: `/company/${id}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}
// 获取经感
export async function getExp(companyId, interviewExperienceType, post, target, workExperience, current) {
    try {
        return await request({
            url: `/posts/interviewExperience?size=20&companyId=${companyId ? companyId :''}&interviewExperienceType=${interviewExperienceType ? interviewExperienceType:''}&post=${post ? post:''}&target=${target? target:''}&workExperience=${workExperience?workExperience:''}&current=${current?current:1}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

//获取面经问题
export async function getExpQuestions(companyId, isExplain, current) {
    try {
        return await request({
            url: `/posts/interviewExperienceDetail?size=20&companyId=${companyId ? companyId :''}&isExplain=${isExplain? isExplain:'false'}&current=${current?current:1}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

// 发布问题
export async function publishQuestion(data) {
    try {
        return await request({
            url: `/posts/interviewExperienceDetail`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }

}

// 搜索公司
export async function searchCompanys(keyWord) {
    try {
        return await request({
            url: `/company/select?keyWord=${keyWord}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}

// 浏览
export async function browse(id) {
    try {
        return await request({
            url: `/browse/` + id,
            method: 'post',
        });
    } catch (error) {
        return {};
    }
}
// 发布面经
export async function publishExp(data) {
    try {
        return await request({
            url: `/posts/interviewExperience`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}

// 获取经验基础详情
export async function getExpBasicDetail() {
    try {
        return await request({
            url: `/interviewExperience/basics`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}


// 获取面经详情
export async function getInterviewDetail(id) {
    try {
        return await request({
            url: `/interviewExperience/${id}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }

}
// 获取面经详情评论
export async function getInterviewDetailComments(interviewExperienceId, current) {
    try {
        return await request({
            url: `/publicComment/comment/${interviewExperienceId}?current=${current?current:1}&type=1`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }

}
// 发布面经详情评论
export async function publishInterviewDetailComment(data) {
    try {
        return await request({
            url: `/publicComment/comment`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }

}
// 获取问题详情
export async function getQuestionDetail(id) {
    try {
        return await request({
            url: `/interviewExperienceDetail/${id}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }

}
// 获取问题详情评论
export async function getQuestionDetailComments(interviewExperienceDetailId, current) {
    try {
        return await request({
            url: `/publicComment/comment/${interviewExperienceDetailId}?current=${current?current:1}&type=2`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }

}
// 发布问题详情评论
export async function publishQuestionDetailComment(data) {
    try {
        return await request({
            url: `/publicComment/comment`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}
// 我也想问
export async function alsoAsk(id, type) {
    try {
        return await request({
            url: `/interviewExperienceDetail/alsoAsk/${id}/${type}`,
            method: 'post'
        });
    } catch (error) {
        return {};
    }

}
// 回答问题
export async function answerQuestion(data) {
    try {
        return await request({
            url: `/interviewExperienceDetail/explain`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }

}
// 发布建议/更正
export async function publishAdvice(data) {
    try {
        return await request({
            url: `/company/errorCorrection`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }

}
// 获取面经子级评论
export async function getInterviewChildComments(commentId, page) {
    try {
        return await request({
            url: `/publicComment/commentChildren/${commentId}?current=${page? page :1}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}

// 获取问题子级评论
export async function getQuestionChildComments(commentId, page) {
    try {
        return await request({
            url: `/publicComment/commentChildren/${commentId}?current=${page? page :1}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}
// 获取热门公司
export async function getHotCompanys() {
    try {
        return await request({
            url: `/company/select?haveInternalRecommendation=true`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}
// 内推相关
export async function getInternalRecommendation() {
    try {
        return await request({
            url: `/internalRecommendation`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}
export async function confirmRefer(data) {
    try {
        return await request({
            url: `/internalRecommendation`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}
// 获取公司资源
export async function getCompanyResource(params) {
    try {
        return await request({
            url: `/company/careerFair`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}
// 获取学校列表
export async function getSchools(name) {
    try {
        return await request({
            url: `/university/select?name=${name}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}